
   
import React from 'react'
import { Container, Row} from "react-bootstrap";
import Breadcrumbs from "../Blocks/Breadcrumbs"

import Seo from '../seo'

function ThankYou(props) {



    return (
      <>
      <Seo
          title={props.content.seoTitle || props.content.h1}
          description={props.content.seoDescription}
          metas={props.global.defaults.metas}
      />
    <Breadcrumbs home={props.global.defaults.lang.home} contentH1={props.content.h1}/>
    <Container fluid style={{background: 'transparent'}}>
        <Container>
        <div id='h1p'>
            <h1 id='leadh1' style={{textAlign: 'center'}}>{props.content.h1}</h1>
            <p id='leadsubtitle' style={{textAlign: 'center'}}>{props.content.subTitle || ''}</p>
        </div>
        <Row style={{paddingBottom : 100}}>
    
        </Row>
        </Container>
        </Container>
       
        </>
    )
}



export default ThankYou