import React from 'react'
import { graphql } from "gatsby"
import Page from '../components/Pages/Page';
import ThankYou from '../components/Pages/ThankYou';

function ThankYouTemplate(props) {
    
    return (
        <Page {...props} PageComponent={ ThankYou } />
    )

}

export const queryThankYou = graphql`
fragment thankYouFields on NewSites {
    ...globalFields
    pages {
        type
        id
        aUri
        content {
            h1
            subTitle
            text
            image
            images
            seoTitle
            seoDescription
            oneTwoThree{
                image1
                image2
                image3
                text1
                text2
                text3
                title1
                title2
                title3
            }
            hero {
                cta
                image
                link
                subTitle
                title
                titleMobile
            }
            stars{
                text1
                text2
                text3
            }
            ticks{
                text1
                text2
                text3
                text4
                title1
                title2
                title3
                title4
            }
            what{
                image
                text1
                text2
                title
            }
            features{
                title
                subTitle
                icon1
                text1
                title1
                url1
                icon2
                text2
                title2
                url2
                icon3
                text3
                title3
                url3
                icon4
                text4
                title4
                url4
                icon5
                text5
                title5
                url5
                icon6
                text6
                title6
                url6
            }
            accordion{
                a1
                a2
                a3
                a4
                a5
                q1
                q2
                q3
                q4
                q5
            }
            carousel{
                images
                title
                subTitle
            }
            salesPitch{
                list
                quote
                quoteImage
            }
            formLead{
                field1
                field2
                field3
                field4
                button
            }
            formContact{
                field1
                field2
                field3
                field4
                button
            }
        }
    }
}
query thankYouQuery($subDomain: String) {
    newSites(subDomain: {eq: $subDomain}) {
        ...thankYouFields
    }
}`

export default ThankYouTemplate


